import { getAppClientData } from '@/services/app-client.client';
import { KsAppClientDataAndMeta } from '@knapsack/types';
import { extractMetaState } from '../site/utils/extract-meta-state';
import { ContentSrc, Env } from './env-and-content-src.types';

export function getEnvWebsocketUrl({
  envUrl,
  websocketsPort,
}: {
  envUrl: string | URL;
  websocketsPort: string | number;
}): string | undefined {
  if (!websocketsPort) return undefined;
  const url = typeof envUrl === 'string' ? new URL(envUrl) : envUrl;
  const protocol = url.protocol === 'https:' ? 'wss' : 'ws';
  return `${protocol}://${url.hostname}:${websocketsPort}`;
}

export function extractPossibleInstanceIdFromSite(site: {
  contentSrc: ContentSrc;
}): string | null {
  if (!site) return null;
  if (site.contentSrc.type !== 'cloud-authoring') return null;
  if (site.contentSrc.instance.type !== 'branch') return null;
  return site.contentSrc.instance.instanceId;
}

export function getEnvDetailsFromAppClientData({
  appClientData,
  envUrl,
  prodEnvUrl,
}: {
  appClientData: KsAppClientDataAndMeta;
  envUrl: string;
  prodEnvUrl: string;
}): Env | Error {
  try {
    const url = new URL(envUrl);
    const prodUrl = new URL(prodEnvUrl);

    const {
      metaState,
      patternsState: { renderers },
      assetSetsState,
    } = appClientData;

    const appClientMeta = extractMetaState(metaState);
    const websocketsEndpoint = getEnvWebsocketUrl({
      envUrl,
      websocketsPort: appClientMeta.websocketsPort,
    });
    if (appClientMeta.mode === 'start') {
      return {
        type: 'development',
        url: url.toString(),
        appClientMeta,
        renderersMeta: renderers,
        assetSetsState,
        websocketsEndpoint,
      };
    }

    return {
      url: url.toString(),
      appClientMeta,
      renderersMeta: renderers,
      assetSetsState,
      type: url.toString() === prodUrl.toString() ? 'production' : 'preview',
    };
  } catch (e) {
    return e;
  }
}

export async function getEnvDetails({
  envUrl,
  prodEnvUrl,
}: {
  envUrl: string;
  prodEnvUrl: string;
}): Promise<Env | Error> {
  if (!envUrl) {
    return new Error('No appClientUrl is provided');
  }

  const appClientData = await getAppClientData({
    appClientUrl: envUrl,
  });

  return getEnvDetailsFromAppClientData({
    appClientData,
    envUrl,
    prodEnvUrl,
  });
}
